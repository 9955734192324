import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function Protected ({ children }: any) {
    const { webToken } = useContext(AuthContext);
    console.log(webToken);
    if (!webToken) {
        return <Navigate to="/sign-in" replace />
    }
    return children;
}

export default Protected;