import CSVScan from "../interface/csv/scan";

export const notAllowedChars = [",", `"`];

export function validateCSV(results: CSVScan, templateHeader: any[]): true | string {
    const isScanValid = scanValid(results, templateHeader);
    if (isScanValid !== true) return isScanValid;

    const areHeaderValid = headersValid(results, templateHeader);
    if (areHeaderValid !== true) return areHeaderValid;

    const isDataValid = dataValid(results, templateHeader);
    if (isDataValid !== true) return isDataValid; 

    return true;
}

function scanValid(results: CSVScan, headers: any[]): true | string {
    const errors = headers.length === 1 ? results.errors[0].filter((error: any) => error.code !== 'UndetectableDelimiter') : results.errors;
    if (errors.length) {
        let errMsg = "";
        for (const err of errors) {
            errMsg += err.message;
        }
        return errMsg;
    }
    return true; 
}

function headersValid(results: CSVScan, templateHeader: any[]): true | string {
    console.log(results.data);
    if (!Array.isArray(results.data) && !results.data[0]) return "No Data found in CSV";
    const headers = results.data[0];
    if (templateHeader.length !== headers.length) return "Header do not match required headers by length";
    for (let i = 0; i < templateHeader.length; i++) {
        if (templateHeader[i] !== headers[i]) return `Header do not match required headers. Header "${headers[i]}" should be "${templateHeader[i]}"`;
    }
    return true;
}

function dataValid(results: CSVScan, templateHeader: any[]): true | string {
    const data: any[] = results.data.slice(1);
    if (data.length === 0) return "No Data found in CSV";
    console.log(data);
    for (let i = 0; i < data.length; i++) {
        if (templateHeader.length !== data[i].length) return `Row ${i+1} does not have so many columns as the headers: ${JSON.stringify(data[i])}`;
    }
    return true;
}