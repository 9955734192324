import { Alert, Button, Grid, Input, InputLabel, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Party from "../../interface/party";
import { createParty } from "../../services/api.service";

/**
 * Create Party 
 *  id: number,
    color: string;
    name: string;
    shortname: string;
 * 
 */
function PartyDetail({ party }: { party?: Party } ) {

    const [message, setMessage] = useState<JSX.Element>();

    const { handleSubmit, control } = useForm();
    const onSubmit = async (data: any) => {
        let party = {
            name: data.name,
            color: data.color,
            shortname: data.shortname,
            logoUrl: data.logoUrl
        };
        const res = await createParty(party);
        console.log(res);
        if (res.inserted) setMessage(<Alert severity="success">Created Party</Alert>);
    }

    return (
        <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Partei</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <form>
                    <Grid item container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Parteiname</InputLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <InputLabel>Abkürzung der Partei</InputLabel>
                            <Controller
                                name="shortname"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Parteifarbe</InputLabel>
                            <Controller
                                name="color"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input type="color" {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                        <InputLabel>Partei Logo URL</InputLabel>
                            <Controller
                                name="logoUrl"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                            
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default PartyDetail;
