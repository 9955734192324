import Wahlkreise from "../interface/Wahlkreise";

const CANTON_WAHLKRESE: Wahlkreise[] = [
    {
        wahlkreis_id: 2,
        name: "Aargau",
        kanton: "AG"
    },
    {
        wahlkreis_id: 3,
        name: "Appenzell Innerrhoden",
        kanton: "AI"
    },
    {
        wahlkreis_id: 4,
        name: "Appenzell Ausserrhoden",
        kanton: "AR"
    },
    {
        wahlkreis_id: 5,
        name: "Bern",
        kanton: "BE"
    },
    {
        wahlkreis_id: 6,
        name: "Basel-Landschaft",
        kanton: "BL"
    },
    {
        wahlkreis_id: 7,
        name: "Basel-Stadt",
        kanton: "BS"
    },
    {
        wahlkreis_id: 8,
        name: "Freiburg",
        kanton: "FR"
    },
    {
        wahlkreis_id: 9,
        name: "Genf",
        kanton: "GE"
    },
    {
        wahlkreis_id: 10,
        name: "Glarus",
        kanton: "GL"
    },
    {
        wahlkreis_id: 11,
        name: "Graubünden",
        kanton: "GR"
    },
    {
        wahlkreis_id: 12,
        name: "Jura",
        kanton: "JU"
    },
    {
        wahlkreis_id: 13,
        name: "Luzern",
        kanton: "LU"
    },
    {
        wahlkreis_id: 14,
        name: "Neuenburg",
        kanton: "NE"
    },
    {
        wahlkreis_id: 15,
        name: "Nidwalden",
        kanton: "NW"
    },
    {
        wahlkreis_id: 16,
        name: "Obwalden",
        kanton: "OW"
    },
    {
        wahlkreis_id: 17,
        name: "Sankt Gallen",
        kanton: "SG"
    },
    {
        wahlkreis_id: 18,
        name: "Schaffhausen",
        kanton: "SH"
    },
    {
        wahlkreis_id: 19,
        name: "Solothurn",
        kanton: "SO"
    },
    {
        wahlkreis_id: 20,
        name: "Schwyz",
        kanton: "SZ"
    },
    {
        wahlkreis_id: 21,
        name: "Thurgau",
        kanton: "TG"
    },
    {
        wahlkreis_id: 22,
        name: "Tessin",
        kanton: "TI"
    },
    {
        wahlkreis_id: 23,
        name: "Uri",
        kanton: "UR"
    },
    {
        wahlkreis_id: 24,
        name: "Waadt",
        kanton: "VD"
    },
    {
        wahlkreis_id: 25,
        name: "Wallis",
        kanton: "VS"
    },
    {
        wahlkreis_id: 26,
        name: "Zug",
        kanton: "ZG"
    },
    {
        wahlkreis_id: 27,
        name: "Zürich",
        kanton: "ZH"
    }
];


export function findCantonWahlkreisByInput(inputStr: string) {
    return CANTON_WAHLKRESE.find((wahlkreise => wahlkreise.kanton === inputStr));
}