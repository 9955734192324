import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateCandidate } from "../../../services/api.service";
import Candidate from "../../../interface/candidate";
import UpdateCandidateDTO from "../../../interface/dto/update/candidate";

function CandidateDetailDialog({ candidate, open, onClose }: { candidate?: Candidate, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            candidateName: "",
            double: 0,
            already: 0,
            gender: "W",
            listId: 0,
            listPlace: 1,
            profession: "",
            yearBorn: 2000
        }
    });

    useEffect(() => {
        if (candidate) {
            setValue("candidateName", candidate.candidateName);
            setValue("double", candidate.double ? 1 : 0);
            setValue("already", candidate.already ? 1 : 0);
            setValue("gender", candidate.gender);
            setValue("listId", candidate.listID);
            setValue("listPlace", candidate.listPlace);
            setValue("profession", candidate.profession);
            setValue("yearBorn", candidate.yearBorn);
        };
    }, [candidate, setValue])

    const onSubmit = async (data: any) => {
        if (!candidate) throw Error("No Candidate set");
        let updatedCandidate: UpdateCandidateDTO = {
            candidate_id: candidate.candidateID,
            list_id: data.listId,
            name: data.candidateName,
            profession: data.profession,
            gender: data.gender,
            year_born: data.yearBorn,
            already: data.already,
            list_place: data.listPlace,
            double: data.double
        };
        const res = await updateCandidate(updatedCandidate);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated Candidate, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Kandidat {candidate?.email} Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Listen ID</InputLabel>
                                    <Controller
                                        name="listId"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <InputLabel>Listenplatz</InputLabel>
                                    <Controller
                                        name="listPlace"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Kandidaten Name</InputLabel>
                                    <Controller
                                        name="candidateName"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Beruf</InputLabel>
                                    <Controller
                                        name="profession"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Gender (M oder W)</InputLabel>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Geburtsjahr</InputLabel>
                                    <Controller
                                        name="yearBorn"
                                        control={control}
                                        rules={{ required: true, min: 1900, max: (new Date().getFullYear()-18) }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Bereits (0 = Nein; 1 = Ja)</InputLabel>
                                    <Controller
                                        name="already"
                                        control={control}
                                        rules={{ required: true, min: 0, max: 1}}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Doppelt (0 = Nein; 1 = Ja)</InputLabel>
                                    <Controller
                                        name="double"
                                        control={control}
                                        rules={{ required: true, min: 0, max: 1}}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CandidateDetailDialog;
