import { Add, Download, Remove } from "@mui/icons-material";
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import defaultColDef from "../../constants/defaultColDef";
import Party from "../../interface/party";
import { deleteParty, getParties } from "../../services/api.service";

function PartiesDashboard(): JSX.Element {
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [showEditButtons, setShowEditButtons] = useState<Boolean>(false);
    const [selectedParty, setSelectedParty] = useState<Party | null>(null);

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    }

    const selectionChanged = () => {
        const selectedRow = gridApi?.getSelectedRows();
        if (selectedRow) {
            setShowEditButtons(true);
            setSelectedParty(selectedRow[0]);
        } else {
            setShowEditButtons(false);
        }
    }

    const deletePartyHere = async (id: number) => {
        const res = await deleteParty(id);
        if (res.deleted) {
            gridApi?.applyTransaction({ remove: [selectedParty] })
            setSelectedParty(null);
            setShowEditButtons(false);
        }
    }

    const { data, error, isLoading } = useQuery<Party[]>(["parties"], async () => await getParties());

    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (data) {
        return (
            <Grid container p={2} spacing={2}>
                <Grid item xs={8}>
                    <Typography variant={"h5"}>Parteien</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="end">
                    <CSVLink
                        data={data}
                        filename={`$parties.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <Button sx={{ ml: 1 }} variant="contained" onClick={() => navigate(`/parties/create`)}><Add /></Button>
                    {showEditButtons ? <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => {
                        if (selectedParty) deletePartyHere(selectedParty.id);
                    }}><Remove /></Button> : null}
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            onGridReady={handleGridReady}
                            onSelectionChanged={selectionChanged}
                            rowData={data}
                            getRowId={(p) => p.data.id}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            columnDefs={[
                                { field: 'id' },
                                { field: 'name' },
                                { field: 'color' },
                                { field: 'shortname' },
                                { field: 'partyLogoUrl' },
                            ]}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
    return <></>;
}

export default PartiesDashboard;