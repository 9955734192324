import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateCriteria } from "../../../services/api.service";
import Criteria from "../../../interface/criteria";
import CriteriaDTO from "../../../interface/dto/criteria";

function CriteriaDetailDialog({ criteria, open, onClose }: { criteria?: Criteria, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            criteriaName: "Name",
            defaultValue: 5,
        }
    });

    useEffect(() => {
        if (criteria) {
            setValue("criteriaName", criteria.criteriaName);
            setValue("defaultValue", criteria.defaultValue);
        }
    }, [criteria, setValue])

    const onSubmit = async (data: any) => {
        if (!criteria) throw Error("No Category set");
        let updatedCriteria: CriteriaDTO = {
            id: criteria.id.toString(),
            election_id: criteria.electionID.toString(),
            criteria_name: data.criteriaName,
            default_value: data.defaultValue,
        };
        const res = await updateCriteria(updatedCriteria);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated Criteria, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Kriterium Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Name</InputLabel>
                                    <Controller
                                        name="criteriaName"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Default Value</InputLabel>
                                    <Controller
                                        name="defaultValue"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CriteriaDetailDialog;
