import { Alert, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { FragenHeader } from "../../constants/csvTemplates";
import QuestionCategory from "../../interface/question-category";
import { createQuestions, getQuestionCategories } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";

function QuestionsBulkImportPage() {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();
    const [categoryMap, setCategoryMap] = useState<Map<number, QuestionCategory>>();

    const { data: categories } = useQuery<QuestionCategory[]>(["questions-categories", { electionID }], async () => await getQuestionCategories(electionID));

    useEffect(() => {
        if (categories && !categoryMap) {
            let tmpCMap = new Map<number, QuestionCategory>();
            for (const c of categories) tmpCMap.set(c.id, c);
            if (tmpCMap.size === 0) {
                setMessage(<Alert severity="warning">In dieser Wahl wurden keine Fragenkategorien gefunden. Erstelle zuerst Kategorien um Fragen erstellen zu können.</Alert>)
            } else {
                setCategoryMap(tmpCMap);
            }
        }
    }, [categories, categoryMap]);

    const onUploadAccepted = async (results: any) => {
        console.log(results);
        const isValid = validateCSV(results, FragenHeader);
        if (isValid === true) {
            const questionsRaw = results.data.slice(1);
            const questions = [];
            for (const q of questionsRaw) {
                questions.push({
                    category_id: q[0],
                    isInShortMode: q[5].toLowerCase() === "true" ? true : false,
                    text: q[1],
                    info: q[2],
                    pro: q[3],
                    contra: q[4],
                });
            }

            // validate custom questions
            // validation rules: category id must match a category; 
            if (!categoryMap) {
                setMessage(<Alert severity="error">Fragen Kategorien konnten nicht geholt werden.</Alert>);
                return;
            }

            for (const q of questions) {
                if (!categoryMap.get(parseInt(q.category_id))) {
                    setMessage(<Alert severity="error">Kategorie mit ID: {q.category_id} konnte nicht gefunden werden. Objekt: {JSON.stringify(q)}</Alert>);
                    return;
                }
            }

            if (questions.length) {
                const res = await createQuestions(electionID, { questions: questions });
                if (res) {
                    setMessage(<Alert severity="success">Questions created</Alert>);
                    return;
                } else {
                    setMessage(<Alert severity="error">Error while adding Questions</Alert>);
                    return;
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
            return;
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Fragen</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default QuestionsBulkImportPage;
