import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

function AppContainer() {
    return (
        <>
            <Navbar />
            <Outlet />
        </>);
}

export default AppContainer;