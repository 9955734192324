import Answer from '../interface/answer';
import Candidate from '../interface/candidate';
import Criteria from '../interface/criteria';
import AnswerDTO from '../interface/dto/answer';
import CandidateDTO from '../interface/dto/candiate';
import CriteriaDTO from '../interface/dto/criteria';
import ElectionDTO from '../interface/dto/election';
import ListDTO from '../interface/dto/list';
import QuestionDTO from '../interface/dto/question';
import Election from '../interface/election';
import List from '../interface/list';
import Question from '../interface/question';
import { dtoToAnswer, dtoToCandidate, dtoToCriteria, dtoToElection, dtoToImpact, dtoToList, dtoToParty, dtoToQuestion, dtoToQuestionCategory, dtoToWahlkreise } from './parse.service';
import QuestionCategoryDTO from '../interface/dto/question-category';
import QuestionCategory from '../interface/question-category';
import PartyDTO from '../interface/dto/party';
import Party from '../interface/party';
import Wahlname from '../interface/Wahlname';
import WahlkreiseDTO from '../interface/dto/wahlkreise';
import Wahlkreise from '../interface/Wahlkreise';
import Impact from '../interface/impact';
import ImpactDTO from '../interface/dto/impact';
import { api } from '../axios';
import UpdateResult from '../interface/dto/events/updateResult';
import UpdateCandidateDTO from '../interface/dto/update/candidate';
import UpdateQuestionDTO from '../interface/dto/update/question';

export async function getElections(): Promise<Election[]> {
    try {
        const { data } = await api.get<ElectionDTO[]>(`/elections`);
        const elections: Election[] = [];
        for (const eDTO of data) {
            elections.push(dtoToElection(eDTO));
        }
        return elections;
    } catch(err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getElection(id: number): Promise<Election> {
    try {
        const { data } = await api.get<ElectionDTO>(`/elections/${id}`);
        return dtoToElection(data);
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getAnswers(id: number): Promise<Answer[]> {
    try {
        const { data } = await api.get<AnswerDTO[]>(`/answers/${id}`, { headers: { "X-ElectID": id }});
        const answers: Answer[] = [];
        for (const aDTO of data) {
            answers.push(dtoToAnswer(aDTO));
        }
        return answers;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getImpacts(id: number): Promise<any[]> {
    try {
        const { data } = await api.get<ImpactDTO[]>(`/impact/${id}`, { headers: { "X-ElectID": id }});
        const impact: Impact[] = [];
        for (const iDTO of data) {
            impact.push(dtoToImpact(iDTO));
        }
        return impact;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}


export async function getCriteria(id: number): Promise<Criteria[]> {
    try {
        const { data } = await api.get<CriteriaDTO[]>(`/criteria/${id}`, { headers: { "X-ElectID": id }});
        const criteria: Criteria[] = [];
        for (const cDTO of data) {
            criteria.push(dtoToCriteria(cDTO));
        }
        return criteria;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getQuestions(id: number): Promise<Question[]> {
    try {
        const { data } = await api.get<QuestionDTO[]>(`/questions/${id}?mode=exact`, { headers: { "X-ElectID": id }});
        const questions: Question[] = [];
        for (const qDTO of data) {
            questions.push(dtoToQuestion(qDTO));
        }
        return questions;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getCandidates(id: number): Promise<Candidate[]>  {
    try {
        const { data } = await api.get<CandidateDTO[]>(`/candidates/${id}`, { headers: { "X-ElectID": id }});
        const candidates: Candidate[] = [];
        for (const cDTO of data) {
            candidates.push(dtoToCandidate(cDTO));
        }
        return candidates;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function updateCandidate(candidate: UpdateCandidateDTO): Promise<UpdateResult> {
    const {data} = await api.put<UpdateResult>("/candidates", candidate);
    return data;
}

export async function getLists(id: number): Promise<List[]>  {
    try {
        const { data } = await api.get<ListDTO[]>(`/lists/${id}`, { headers: { "X-ElectID": id }});
        const lists: List[] = [];
        for (const lDTO of data) {
            lists.push(dtoToList(lDTO));
        }
        return lists;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getQuestionCategories(id: number): Promise<QuestionCategory[]> {
    try {
        const { data } = await api.get<QuestionCategoryDTO[]>(`/categories/${id}`, { headers: { "X-ElectID": id }});

        const category: QuestionCategory[] = [];
        for (const cDTO of data) {
            category.push(dtoToQuestionCategory(cDTO));
        }
        return category;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function updateCategory(category: { id: number, name: string}): Promise<UpdateResult> {
    const { data } = await api.put<UpdateResult>("/categories", category);
    return data;
}

export async function updateCriteria(criteria: CriteriaDTO): Promise<UpdateResult> {
    const { data } = await api.put<UpdateResult>("/criteria", criteria);
    return data;
}

export async function updateList(list: Partial<ListDTO>): Promise<UpdateResult> {
    const { data } = await api.put<UpdateResult>("/lists", list);
    return data;
}

export async function updateQuestion(question: UpdateQuestionDTO): Promise<UpdateResult> {
    const { data } = await api.put<UpdateResult>("/questions", question);
    return data;
}

export async function updateImpact(impact: {impact: number, answer_id: number, criteria_id: number}): Promise<UpdateResult> {
    const { data } = await api.put<UpdateResult>("/impact/update", impact);
    return data;
}

export async function getParties(): Promise<Party[]> {
    try {
        const { data } = await api.get<PartyDTO[]>(`/parties/`);
        const parties: Party[] = [];
        for (const p of data) {
            parties.push(dtoToParty(p));
        }
        return parties;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getWahlen(): Promise<Wahlname[]> {
    try {
        const { data } = await api.get<Wahlname[]>(`/wahlen/`);
        return data;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function getWahlkreise(): Promise<Wahlkreise[]> {
    try {
        const { data } = await api.get<WahlkreiseDTO[]>(`/wahlkreise/all/`);
        const wahlkreise: Wahlkreise[] = [];
        for (const w of data) {
            wahlkreise.push(dtoToWahlkreise(w));
        }
        return wahlkreise;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function createElection(wahlInfo: any): Promise<any> {
    try {
        const { data } = await api.post(`/elections/`,{
            wahlInfo: wahlInfo
        }, );
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createQuestionCategories(id: number, categories: any): Promise<any> {
    try {
        const { data } = await api.post(`/categories/${id}`,categories, );
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}


export async function createParty(party: any): Promise<any> {
    try {
        const { data } = await api.post(`/parties/`, party, );
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createWahlname(wahlname: any): Promise<any> {
    try {
        const { data } = await api.post(`/wahlen/`, wahlname, );
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}


export async function createBewertungskriterien(id: number, criteria: any): Promise<any> {
    try {
        const { data } = await api.post(`/criteria/${id}`, criteria, );
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createCandidates(id: number, candidates: any): Promise<any> {
    try {
        const { data, status } = await api.post(`/candidates/${id}`, candidates, );
        console.log(data); 
        return status === 200;
    } catch (err: unknown) {
        console.error(err);
        throw err;
    }
}

export async function createQuestions(id: number, questions: any): Promise<any> {
    try {
        const { data, status } = await api.post(`/questions/${id}`, questions, );
        console.log(data); 
        return status === 200;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createLists(id: number, lists: any): Promise<any> {
    try {
        const { data, status } = await api.post(`/lists/${id}`, lists, );
        console.log(data); 
        return status === 200;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createImpact(impacts: any): Promise<any> {
    try {
        const { data, status } = await api.post(`/impact/`, impacts, );
        console.log(data); 
        return status === 200;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function deleteParty(id: number): Promise<any> {
    try {
        const { data, status } = await api.delete(`/parties/${id}`);
        console.log(data); 
        return status === 200;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function makePublic(id: number): Promise<any> {
    try {
        const { data } = await api.post(`/elections/make-public/${id}`, { headers: { "X-ElectID": id }});
        console.log(data); 
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function sendInvitationMails(id: number): Promise<any> {
    try {
        const { data } = await api.get(`/candidates/publish/${id}`, { headers: { "X-ElectID": id }});
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    }
}

export async function createWahlkreise(wahlkreise: Partial<Wahlkreise>[]) {
    try {
        const { data } = await api.post(`/wahlkreise/`, wahlkreise);
        return data;
    } catch (err: unknown) {
        console.error(err);
        return false;
    } 
}
