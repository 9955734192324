import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound(): JSX.Element {
    const navigate = useNavigate();
    return (
    <>
        <Grid container spacing={1}>
            <Grid item xs={12} display="flex" justifyContent="center">
                <h1>UPS! Seite wurde nicht gefunden</h1>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => navigate("/dashboard")}>Go to Dashboard</Button>
            </Grid>
        </Grid>
    </>
    );
}

export default NotFound;