import { Grid, Typography, Button, LinearProgress, Alert } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import defaultColDef from "../../../constants/defaultColDef";
import { getImpacts } from "../../../services/api.service";
import { CSVLink } from "react-csv";
import { Add, Download, ModeEdit } from "@mui/icons-material";
import { ImpactTemplate } from "../../../constants/csvTemplates";
import Impact from "../../../interface/impact";
import { useState } from "react";
import { SelectionChangedEvent } from "ag-grid-community";
import ImpactDetailDialog from "../../Detail/ElectionDialogs/ImpactDetailDialog";

function ImpactDashboard(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? parseInt(params.id) : 0;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedImpact, setSelectedImpact] = useState<Impact>();
    
    const { data, isLoading, error } = useQuery<Impact[]>(["impact", { id }], async () => await getImpacts(id)); 

    const handleModalClose = () => setModalOpen(false);

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows: Impact[] = event.api.getSelectedRows();
        setSelectedImpact(selectedRows.length ? selectedRows[0] : undefined);
    }
    

    if (isLoading) return <LinearProgress />;
    if (error) return <Alert severity="error">Error Loading</Alert>;

    if (data) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Typography variant={"h5"}>Impact</Typography>
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="end">
                    <CSVLink
                        data={data}
                        filename={`${id}-impact.csv`}
                        target="_blank"
                        style={{textDecoration: "none"}}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <CSVLink
                        data={ImpactTemplate}
                        filename={`template-impact.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
                    {selectedImpact && <Button sx={{ml: 1}} variant="contained" color="success" onClick={() => setModalOpen(true)}><ModeEdit /></Button>}
                    <Button sx={{ml: 1}} variant="contained" onClick={() => navigate(`/election/${id}/impact/create`)}><Add /></Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            rowData={data}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            onSelectionChanged={onSelectionChanged}
                            columnDefs={[
                                { field: 'id' },
                                { field: 'answerID' },
                                { field: 'criteriaId' },
                                { field: 'criteriaName' },
                                { field: 'defaultValue' },
                                { field: 'impact' }
                            ]}
                        />
                    </div>
                </Grid>
                <ImpactDetailDialog
                    open={modalOpen}
                    impact={selectedImpact}
                    onClose={handleModalClose}
                />
            </Grid>
        );
    }

    return <></>;
}


export default ImpactDashboard;