import { Add, Download, ModeEdit } from "@mui/icons-material";
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BewertungskriterienTemplate } from "../../../constants/csvTemplates";
import defaultColDef from "../../../constants/defaultColDef";
import Criteria from "../../../interface/criteria";
import { getCriteria } from "../../../services/api.service";
import { useState } from "react";
import { SelectionChangedEvent } from "ag-grid-community";
import CriteriaDetailDialog from "../../Detail/ElectionDialogs/CriteriaDetailDialog";

function CriteriaDashboard(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? parseInt(params.id) : 0;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCriteria, setSelectedCriteria] = useState<Criteria>();

    const { data, error, isLoading } = useQuery<Criteria[]>(["election-criteria", { id }], async () => await getCriteria(id));

    const handleModalClose = () => setModalOpen(false);

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows: Criteria[] = event.api.getSelectedRows();
        setSelectedCriteria(selectedRows.length ? selectedRows[0] : undefined);
    }

    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (data) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Typography variant={"h5"}>Bewertungskritieren</Typography>
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="end">
                    <CSVLink
                        data={data}
                        filename={`${id}-bewertungskritieren.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <CSVLink
                        data={BewertungskriterienTemplate}
                        filename={`template-bewertungskriterien.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
                    {selectedCriteria && <Button sx={{ml: 1}} variant="contained" color="success" onClick={() => setModalOpen(true)}><ModeEdit /></Button>}
                    <Button sx={{ ml: 1 }} variant="contained" onClick={() => navigate(`/election/${id}/criteria/create`)}><Add /></Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            rowData={data}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            onSelectionChanged={onSelectionChanged}
                            columnDefs={[
                                { field: 'id' },
                                { field: 'criteriaName' },
                                { field: 'defaultValue' }
                            ]}
                        />
                    </div>
                </Grid>
                <CriteriaDetailDialog
                    open={modalOpen}
                    criteria={selectedCriteria}
                    onClose={handleModalClose}
                />
            </Grid>
        );
    }

    return <></>
}


export default CriteriaDashboard;