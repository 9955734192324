import { createContext, useEffect } from "react";
import { Buffer } from 'buffer';
import { api } from "../axios";
import { useLocalStorage } from "usehooks-ts";

interface AuthContextParams {
    webToken?: string;
    signIn: (credentials: {username?: string, password?: string, storageToken?: string}) => any,
    signOut: () => any,
}

export const AuthContext = createContext<AuthContextParams>({
    webToken: undefined,
    signIn: (credentials: {username?: string, password?: string, storageToken?: string}) => {},
    signOut: () => {}
})

export function AuthProvider(props: any) {
    const [webToken, setWebToken] = useLocalStorage<string | undefined>("webToken", undefined);

    useEffect(() => {
        if (!api.defaults.headers.common["Authorization"] && webToken) {
            api.defaults.headers.common["Authorization"] = `Basic ${webToken}`;
        }
    }, [webToken])

    const signIn = async ({ username, password, storageToken }: {username?: string, password?: string, storageToken?: string}) => {
        if (!username && ! password && !storageToken) return false;
        const token = storageToken ?? new Buffer(`${username}:${password}`).toString('base64');
        const { status } = await api.get(
            `/admin/sign-in`, 
            { headers: { "Authorization": `Basic ${token}` }}
        )
        if (status === 200) {
            api.defaults.headers.common["Authorization"] = `Basic ${token}`;
            setWebToken(token);
            return true;
        }
        return false; 
    }

    const signOut = () => {
        api.defaults.headers.common["Authorization"] = undefined;
        setWebToken(undefined);
    }

    return (
        <AuthContext.Provider value={
            {webToken, signIn, signOut}
        }>
            { props.children }        
        </AuthContext.Provider>
    );
}