import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import QuestionCategory from "../../../interface/question-category";
import { updateCategory } from "../../../services/api.service";

function CategoryDetailDialog({ category, open, onClose }: { category?: QuestionCategory, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm();

    useEffect(() => {
        if (category) setValue("name", category.name);
    }, [category, setValue])

    const onSubmit = async (data: any) => {
        if (!category) throw Error("No Category set");
        let updatedCategory: { id: number, name: string } = {
            id: category.id,
            name: data.name,
        };
        const res = await updateCategory(updatedCategory);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated Category, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Kategorie Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Name</InputLabel>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CategoryDetailDialog;
