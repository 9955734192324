import { Add, Download } from "@mui/icons-material";
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import defaultColDef from "../../constants/defaultColDef";
import Wahlkreise from "../../interface/Wahlkreise";
import { getWahlkreise } from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import { WahlkreisTemplate } from "../../constants/csvTemplates";

function DistrictsDashboard(): JSX.Element {
    const navigate = useNavigate();

    const { data, error, isLoading } = useQuery<Wahlkreise[]>(["wahlkreise"], async () => await getWahlkreise());

    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (data) {
        return (
            <Grid container p={2} spacing={2}>
                <Grid item xs={8}>
                    <Typography variant={"h5"}>Wahlkreise</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="end">
                    <CSVLink
                        data={data}
                        filename={`wahlkreise.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <CSVLink
                        data={WahlkreisTemplate}
                        filename={`template-wahlkreise.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
                    <Button sx={{ ml: 1 }} variant="contained" onClick={() => navigate(`/wahlkreise/create`)}><Add /></Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            rowData={data}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            columnDefs={[
                                { field: 'wahlkreis_id' },
                                { field: 'name' },
                                { field: 'kanton' },
                            ]}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
    return <></>;
}

export default DistrictsDashboard;