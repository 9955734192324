
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar,  Tooltip, MenuItem } from "@mui/material"
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const pages: { name: string, url: string}[] = [
  {name: "Dashboard", url: "/dashboard"},
  {name: "Parteien", url: "/parties"},
  {name: "Wahlen", url: "/wahlen"},
  {name: "Wahlkreise", url: "/wahlkreise"},
  {name: "Smartvote to Vimentis", url: "/smartvote-to-vimentis"},
]
const settings: string[] = ["Logout"];

function Navbar() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(
    null
  );


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (val?: string) => {
    setAnchorElUser(null);
    if(val === "Logout") {
      authContext.signOut()
      navigate("/sign-in")
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{
            display: { xs: "none", md: "flex" },
            mr: 2
          }}>
            <img src="https://vimentis.ch/wp-content/uploads/2021/09/cropped-2021-09-19_Vimentis-Logo_weiss.png" width={200} alt="vimentis logo" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    to={page.url}
                    style={{textDecoration: 'none'}}
                  >
                    <Typography textAlign="center" sx={{textDecoration: "none", fontWeight: "bold", color: "black"}}>{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            mr: 2
          }}>
            <img src="https://vimentis.ch/wp-content/uploads/2021/09/cropped-2021-09-19_Vimentis-Logo_weiss.png" width={200} alt="vimentis logo" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link
                key={page.name}
                onClick={handleCloseNavMenu}
                to={page.url}
                style={{textDecoration: 'none'}}
              >
                <Typography sx={{color: "white", fontWeight: "bold", mr: 2, textDecoration: "none"}}>{page.name}</Typography>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="https://secure.gravatar.com/avatar/7d251c7c3fc4e68747e3180b4ccf0702?s=96&d=mm&r=g" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu()}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
