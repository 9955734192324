import { Download } from "@mui/icons-material";
import { Alert, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { CSVLink } from "react-csv";
import CSVUploader from "../../../components/CSVUploader";
import { SmartvoteTemplate } from "../../../constants/csvTemplates";
import { useQuery } from "react-query";
import Party from "../../../interface/party";
import { getLists, getParties, getWahlen, getWahlkreise } from "../../../services/api.service";
import Wahlkreise from "../../../interface/Wahlkreise";
import Wahlname from "../../../interface/Wahlname";
import { getListPlace, getYearBorn, validateAllowedYesAnswer, validateEmail, validateGender } from "../../../helpers/validation.helper";
import { findCantonWahlkreisByInput } from "../../../helpers/canton.helper";
import { useParams } from "react-router-dom";
import List from "../../../interface/list";


function ListIdCalculator(): JSX.Element {
    const params = useParams();
    const electionId = params.id ? parseInt(params.id) : 0;
    const [candidates, setCandidates] = useState<any[]>();
    const [alert, setAlert] = useState<JSX.Element>();

    const { data: parties, error: partiesError, isLoading: partiesIsLoading } = useQuery<Party[]>(["parties"], async () => await getParties());
    const { data: wahlkreise, error: wahlkreiseError, isLoading: wahlkreiseIsLoading } = useQuery<Wahlkreise[]>(["wahlkreise"], async () => await getWahlkreise());
    const { data: wahlnamen, error: wahlnamenError, isLoading: wahlnamenIsLoading } = useQuery<Wahlname[]>(["wahlen"], async () => await getWahlen());
    const { data: lists, error: listsError, isLoading: listsIsLoading } = useQuery<List[]>(["election-lists", { id: electionId }], async () => await getLists(electionId));

    const onUploadAccepted = async (results: any) => {
        if (!parties || !wahlkreise || !wahlnamen || !lists) {
            setAlert(<Alert severity="error">Partein, Wahlkreise, Listen und Wahlnamen noch nicht geladen für Valdierung. Bitte warten Sie.</Alert>)
            return;
        }

        const tmpCandidates = [];

        let data = results.data.slice(1);
        console.log(data);

        for (const [i, d] of data.entries()) {
            try {
                const listPlace = getListPlace(d[8]);
                const yearBorn = getYearBorn(d[10]);
                const email = d[13].trim();
                const gender = validateGender(d[11]);

                const foundParty = parties.find((party) => party.name.toLowerCase().trim() === d[6].toLowerCase().trim() || party.shortname.toLowerCase().trim() === d[6].toLowerCase().trim());
                const foundWahlkreis = wahlkreise.find((wahlkreis) => wahlkreis.name.toLowerCase().trim() === d[1].toLowerCase().trim()) ?? findCantonWahlkreisByInput(d[1]);
                const foundWahlname = wahlnamen.find((wahlname) =>  wahlname.wahlname.toLowerCase().trim() === d[0].toLowerCase().trim());

                if (!foundParty) throw new Error(`Cannot find Party: ${d[6]} on Line: ${i+2}`);
                if (!foundWahlkreis) throw new Error(`Cannot find Wahlkreis: ${d[1]} on Line: ${i+2}`);
                if (!foundWahlname) throw new Error(`Cannot find Wahlname: ${d[0]} on Line: ${i+2}`);

                const listenname = d[2].trim().length > 0 ? d[2] : foundParty.name;

                const belongingList = lists.find((list) => 
                    list.partyId === foundParty.id && 
                    list.bezirksNr === foundWahlkreis.wahlkreis_id && 
                    list.wahl === foundWahlname.wahlname && 
                    list.name === listenname
                );

                if (!belongingList) throw new Error(`Could not match candiate ${email} with a list on line ${i+2}`);
                if (!validateEmail(email)) throw new Error(`Email ${email} is not a valid email on line: ${i+2}`);
                if (!gender) throw new Error(`Couldnt Match gender String: '${d[11]}' to Male, X or Female Gender on line: ${i+2}`);

                tmpCandidates.push({
                    list_id: belongingList.id,
                    email: email,
                    name: `${d[5]} ${d[4]}`,
                    profession: `${d[18]}`,
                    gender: gender,
                    yearBorn: yearBorn,
                    already: validateAllowedYesAnswer(d[7]),
                    double: false,
                    list_place: listPlace,
                });
                setCandidates(tmpCandidates);
            } catch (error) {
                if (error instanceof Error) setAlert(<Alert severity="error">{error.message}</Alert>)
            }
        }
    }
    
    return (
        <Grid container p={2} spacing={2}>
            <Grid item xs={8}>
                <Typography variant="h5">Smartvote to Vimentis converter</Typography>
            </Grid>
            <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                <CSVLink
                        data={SmartvoteTemplate}
                        filename={`smartvote-template.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
            </Grid>
            <Grid item xs={12}>
                {alert}
                {(wahlkreiseIsLoading || partiesIsLoading || wahlnamenIsLoading || listsIsLoading) && <Alert severity="info">Loading Wahlkreise, Parteien, Wahlnamen, Listen. Please Wait </Alert>}               
                {(wahlkreiseError as Error || partiesError as Error || wahlnamenError as Error, listsError as Error) && <Alert severity="error">Could not load Wahlkreise, Listsor  Parties. Tool will not work properly</Alert>}               
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
            {candidates && (
            <Grid item xs={6}>
                <CSVLink
                    data={candidates}
                    filename={`candidates.csv`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                >
                    <Button variant="contained" startIcon={<Download />}>Download Candidates</Button>
                </CSVLink>
            </Grid>)}
        </Grid>
    );
}

export default ListIdCalculator;