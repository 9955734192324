import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateList } from "../../../services/api.service";
import List from "../../../interface/list";
import ListDTO from "../../../interface/dto/list";

function ListDetailDialog({ list, open, onClose }: { list?: List, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            name: "Name",
            wahlkreis_id: 5,
            wahl: "Wahl",
            party_id: 1
        }
    });

    useEffect(() => {
        if (list) {
            setValue("name", list.name);
            setValue("wahlkreis_id", list.bezirksNr ?? 0);
            setValue("wahl", list.wahl ?? "");
            setValue("party_id", list.partyId);
        }
    }, [list, setValue])

    const onSubmit = async (data: any) => {
        if (!list) throw Error("No List selected");
        let updatedList: Partial<ListDTO> = {
            id: list.id.toString(),
            name: data.name,
            party_id: data.party_id.toString(),
            wahl: data.wahl,
            wahlkreis_id: data.wahlkreis_id,
        };
        const res = await updateList(updatedList);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated List, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Liste Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Name</InputLabel>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Partei ID</InputLabel>
                                    <Controller
                                        name="party_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Wahl</InputLabel>
                                    <Controller
                                        name="wahl"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Bezirks Nr</InputLabel>
                                    <Controller
                                        name="wahlkreis_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ListDetailDialog;
