import { Cancel, CheckCircle } from "@mui/icons-material";
import { Alert, Button, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Election from "../../../interface/election";
import { getElection, makePublic } from "../../../services/api.service";

function OverviewDashboard(): JSX.Element {
    const params = useParams();
    const [showMakePublic, setShowMakePublic] = useState<boolean>(false);
    const [message, setMessage] = useState<JSX.Element>();

    const id = params.id ? parseInt(params.id) : 0;

    let { data: election, error, isLoading } = useQuery<Election>(["election", { id }], async () => await getElection(id));

    useEffect(() => {
        if(election) {
            if (!election.isPublic) setShowMakePublic(true);
        }
    }, [election]);

    const publish = async (id?: number) => {
        if (!id) return;
        const res = await makePublic(id);
        if (res && election) {
            setShowMakePublic(false);
            setMessage(<Alert severity="success">Wahl Veröffentlicht</Alert>);
        }
    }

    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (election) {
        return <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <img src={`${election.image}`} alt={election.name} loading="lazy" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <h1>{election.name}</h1>
                    <p>{election.description}</p>
                </Grid>
                <Grid item xs={12}>
                    {message}
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Veröffentlicht?
                            </Typography>
                            <Typography variant="h5" component="div">
                                {election.isPublic ? <CheckCircle /> : <Cancel />} {election.isPublic}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Mehrere Kantone?
                            </Typography>
                            <Typography variant="h5" component="div">
                                {election.hasCantons ? <CheckCircle /> : <Cancel />} {election.hasCantons}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Wahlkreise?
                            </Typography>
                            <Typography variant="h5" component="div">
                                {election.hasWahlkreise ? <CheckCircle /> : <Cancel />} {election.hasWahlkreise}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Mehrere Wahlen?
                            </Typography>
                            <Typography variant="h5" component="div">
                                {election.multiple ? <CheckCircle /> : <Cancel />} {election.multiple}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {showMakePublic ? 
                    <Button onClick={() => publish(election?.id)} variant="contained" color="primary">
                        Make Public
                    </Button> : null}
                </Grid>
            </Grid>
        </>;
    }
    return <></>;
}


export default OverviewDashboard;