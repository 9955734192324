import { useQuery } from "react-query";
import { getElections } from "../../../services/api.service";
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CellClickedEvent } from "ag-grid-community";
import defaultColDef from "../../../constants/defaultColDef";
import { Add } from "@mui/icons-material";
import Election from "../../../interface/election";

function Dashboard(): JSX.Element {
    const navigate = useNavigate();

    const { data: elections, error, isLoading } = useQuery<Election[]>(["elections", {}], getElections);

    const cellClickedListener = (event: CellClickedEvent) => {
        navigate(`/election/${event.data.id}`)
    }

    if (isLoading) return <LinearProgress />;
    if (error) return <Alert severity="error">Error Loading</Alert>;

    return (
        <Grid container p={2} spacing={2}>
            <Grid item xs={12} md={8}>
                <Typography variant={"h4"}>Dashboard</Typography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="end">
                <Button variant="contained" onClick={() => navigate("/election/create")}><Add /></Button>
            </Grid>
            <Grid item xs={12}>
                <div className="ag-theme-alpine" style={{width: "100%", height: "70vh"}}>
                    <AgGridReact
                        rowData={elections}
                        rowSelection="single"
                        columnDefs={[
                            {field: 'id'},
                            {field: 'name'},
                            {field: 'description'},
                            {field: 'hasCantons'},
                            {field: 'hasWahlkreise'},
                            {field: 'multiple'},
                            {field: 'isPublic'},
                            {field: 'postID'},
                        ]}
                        defaultColDef={defaultColDef}
                        onCellClicked={cellClickedListener}
                    />
                </div>
            </Grid>
        </Grid>
    );

}

export default Dashboard;