export const FragenHeader = ['categoryID', 'text', 'info', 'pro', 'contra', 'isInShortMode'];
export const FragenTemplate = [{ categoryID: 0, text: "Frage", info: "Info", pro: "Pro", contra: "Kontra", isInShortMode: true }];

export const BewertungskriterienHeader = ['criteria_name', 'default_value'];
export const BewertungskriterienTemplate = [
    {criteria_name: "links-rechts", default_value: 5},
    {criteria_name: "konservativ-liberal", default_value: 5},
    {criteria_name: "Offene Aussenpoltik", default_value: 5},
    {criteria_name: "Liberale Wirtschaftspolitik", default_value: 5},
    {criteria_name: "Restriktive Finanzpolitik", default_value: 5},
    {criteria_name: "Law & Order", default_value: 5},
    {criteria_name: "Restriktive Migrationspoltik", default_value: 5},
    {criteria_name: "Ausgebauter Umweltschutz", default_value: 5},
    {criteria_name: "Ausgebauter Sozialstaat", default_value: 5},
    {criteria_name: "Liberale Gesselschaft", default_value: 5},
];

export const ListsHeader = ['party_id', 'listenname', 'wahlkreis_id', 'wahl'];
export const ListsTemplate = [{
    party_id: 0,
    listenname: "Liste 2", 
    wahlkreis_id: 102, 
    wahl: "Regierungsratswahlen"
}];

export const CandidatesHeader = ['list_id', 'email', 'name', 'profession', 'gender', 'yearBorn', 'already', 'double', 'list_place'];
export const CandidatesTemplate = [{
    list_id: 0,
    email: "email@email.com",
    name: "Vorname NAchname",
    profession: "Beruf",
    gender: "Geschlecht (M / W / X)",
    yearBorn: 2000,
    already: true,
    double: false,
    list_place: 1
}];


export const FragenKategorienHeader = ['name'];
export const FragenKategorienTemplate = [
    {name: "Aussenpolitik & Integration Ausländer"},
    {name: "Öffentliche Sicherheit & Rechtssystem"},
    {name: "Sozialpolitik & Gesundheitssystem"},
    {name: "Familien- & Bildungspolitik"},
    {name: "Umwelt, Verkehr, Energie & Kommunikation"},
    {name: "Wirtschaftspolitik, Steuern & Staatsfinanzen"},
    {name: "Umwelt"},
    {name: "Verkehr & Infrastruktur"},
    {name: "Politisches System & Digitalisierung"},
    {name: "Sicherheit & Polizei"},
    {name: "Aussenbeziehungen"}
];



export const ImpactHeader = ['answer_id', 'criteria_id', 'impact'];
export const ImpactTemplate = [{
    answer_id: 0,
    criteria_id: 0,
    impact: 0,
}];

export const WahlkreisHeader = ['name', 'kanton'];
export const WahlkreisTemplate = [{ name: "Wahlkreis Name", kanton: "ZH" }];


export const SmartvoteHeader = [
    "Wahl", "Wahlkreis", "Listenbezeichnung", "Listennummer", "Name", "Vorname", "Partei", "Bisher (ja = 1)", 
    "Listenplatz", "Kandidierenden Nummer", "Jahrgang", "Geschlecht (M, W)", "Korresspondezsprache", 
    "E-Mailadresse", "Adresse", "Plz", "Wohnort", "Land", "Beruf"
];
export const SmartvoteTemplate = [{
    Wahl: "Ständerat",
    Wahlkreis: "Dietikon", 
    Listenbezeichnung: "SP Zürich",
    Listennummer: "A2",
    Name: "Arr",
    Vorname: "Alexander",
    Partei: "SP",
    Bisher: 1,
    Listenplatz: 1,
    Kandidierenden_Nummer: "1D",
    Jahrgang: 2002,
    Geschlecht: "M",
    Korrespondenzsprache: "DE",
    EmailAdresse: "alex@mail.com",
    Adresse: "Bernerstrasse 2",
    Plz: 8000,
    Wohnort: "Zürcih",
    Land: "CHE", 
    Beruf: "Informatiker"
}];