const EMAIL_REGEX = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const allowedYesAnswers = ["1", "ja", "true"];
export const allowedFemaleValues = ["w", "f", "weiblich", "female"];
export const allowedMaleValues = ["m", "männlich", "male"];

export function validateEmail(email: string): boolean {
    return EMAIL_REGEX.test(email);
}

export function validateAllowedYesAnswer(value: string): boolean {
    return allowedYesAnswers.includes(value.toLowerCase().trim());
}

export function validateGender(genderStr: string): "M" | "W" | "X"{
    const parsedGenderStr = genderStr.toLowerCase().trim();
    if (allowedFemaleValues.includes(parsedGenderStr)) return "W";
    if (allowedMaleValues.includes(parsedGenderStr)) return "M";
    return "X";
}

export function getListPlace(listPlaceStr: string): number {
    return isNaN(parseInt(listPlaceStr)) ? 99 : parseInt(listPlaceStr);
}

export function getYearBorn(yearBornStr: string): number {
    return isNaN(parseInt(yearBornStr)) ? 1000 : parseInt(yearBornStr);
}