import { Add, Download, ModeEdit } from "@mui/icons-material";
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FragenTemplate } from "../../../constants/csvTemplates";
import defaultColDef from "../../../constants/defaultColDef";
import Question from "../../../interface/question";
import { getQuestions } from "../../../services/api.service";
import { useState } from "react";
import { SelectionChangedEvent } from "ag-grid-community";
import QuestionsDetailDialog from "../../Detail/ElectionDialogs/QuestionsDetailDialog";

function QuestionsDashboard(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? parseInt(params.id) : 0;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<Question>();

    const { data: questions, error, isLoading } = useQuery<Question[]>(["questions", { id }], async () => await getQuestions(id));

    const handleModalClose = () => setModalOpen(false);

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows: Question[] = event.api.getSelectedRows();
        setSelectedQuestion(selectedRows.length ? selectedRows[0] : undefined);
    }
    
    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (questions) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Typography variant={"h5"}>Fragen</Typography>
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="end">
                    <CSVLink
                        data={questions}
                        filename={`${id}-questions.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <CSVLink
                        data={FragenTemplate}
                        filename={`template-questions.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
                    {selectedQuestion && <Button sx={{ml: 1}} variant="contained" color="success" onClick={() => setModalOpen(true)}><ModeEdit /></Button>}
                    <Button sx={{ml: 1}} variant="contained" onClick={() => navigate(`/election/${id}/questions/create`)}><Add /></Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            rowData={questions}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            onSelectionChanged={onSelectionChanged}
                            columnDefs={[
                                { field: 'id' },
                                { field: 'categoryID' },
                                { field: 'isInShortMode' },
                                { field: 'text' },
                                { field: 'pro' },
                                { field: 'contra' },
                                { field: 'info' },
                            ]}
                        />
                    </div>
                </Grid>
                <QuestionsDetailDialog
                    open={modalOpen}
                    question={selectedQuestion}
                    onClose={handleModalClose}
                />
            </Grid>
        );
    }

    return <></>;
}


export default QuestionsDashboard;