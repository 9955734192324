import { Download } from "@mui/icons-material";
import { Alert, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { CSVLink } from "react-csv";
import CSVUploader from "../components/CSVUploader";
import { SmartvoteTemplate } from "../constants/csvTemplates";
import { useQuery } from "react-query";
import Party from "../interface/party";
import { getParties, getWahlen, getWahlkreise } from "../services/api.service";
import Wahlkreise from "../interface/Wahlkreise";
import Wahlname from "../interface/Wahlname";
import { findCantonWahlkreisByInput } from "../helpers/canton.helper";


function SmartvoteToVimentisConverter(): JSX.Element {

    const [lists, setLists] = useState<any[]>();
    const [alert, setAlert] = useState<JSX.Element>();

    const { data: parties, error: partiesError, isLoading: partiesIsLoading } = useQuery<Party[]>(["parties"], async () => await getParties());
    const { data: wahlkreise, error: wahlkreiseError, isLoading: wahlkreiseIsLoading } = useQuery<Wahlkreise[]>(["wahlkreise"], async () => await getWahlkreise());
    const { data: wahlnamen, error: wahlnamenError, isLoading: wahlnamenIsLoading } = useQuery<Wahlname[]>(["wahlen"], async () => await getWahlen());

    const onUploadAccepted = async (results: any) => {
        if (!parties || !wahlkreise || !wahlnamen) {
            setAlert(<Alert severity="error">Partein, Wahlkreise und Wahlnamen noch nicht geladen für Valdierung. Bitte warten Sie.</Alert>)
            return;
        }

        const tmpLists: Map<string, any> = new Map();

        let data = results.data.slice(1);
        console.log(data);

        for (const [i, d] of data.entries()) {
            try {
                const foundParty = parties.find((party) => party.name.toLowerCase().trim() === d[6].toLowerCase().trim() || party.shortname.toLowerCase().trim() === d[6].toLowerCase().trim());
                const foundWahlkreis = wahlkreise.find((wahlkreis) => wahlkreis.name.toLowerCase().trim() === d[1].toLowerCase().trim()) ?? findCantonWahlkreisByInput(d[1]);
                const foundWahlname = wahlnamen.find((wahlname) =>  wahlname.wahlname.toLowerCase().trim() === d[0].toLowerCase().trim());

                if (!foundParty) throw new Error(`Cannot find Party: ${d[6]} on Line: ${i+2}`);
                if (!foundWahlkreis) throw new Error(`Cannot find Wahlkreis: ${d[1]} on Line: ${i+2}`);
                if (!foundWahlname) throw new Error(`Cannot find Wahlname: ${d[0]} on Line: ${i+2}`);

                const list = {
                    party_id: foundParty.id,
                    listenname: d[2].trim().length > 0 ? d[2] : foundParty.name,
                    wahlkreis_id: foundWahlkreis.wahlkreis_id,
                    wahl: foundWahlname.wahlname,
                };

                tmpLists.set(`${list.party_id}-${list.listenname}-${list.wahlkreis_id}-${list.wahl}`, list)
            } catch (error) {
                if (error instanceof Error) setAlert(<Alert severity="error">{error.message}</Alert>)
            }
        }

        setLists(Array.from(tmpLists.values()));
    }

    return (
        <Grid container p={2} spacing={2}>
            <Grid item xs={8}>
                <Typography variant="h5">Smartvote to Vimentis converter</Typography>
            </Grid>
            <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                <CSVLink
                        data={SmartvoteTemplate}
                        filename={`smartvote-template.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
            </Grid>
            <Grid item xs={12}>
                {alert}
                {(wahlkreiseIsLoading || partiesIsLoading || wahlnamenIsLoading) && <Alert severity="info">Loading Wahlkreise, Parteien, Wahlnamen. Please Wait </Alert>}               
                {(wahlkreiseError as Error || partiesError as Error || wahlnamenError as Error) && <Alert severity="error">Could not load Wahlkreise and Parties. Tool will not work properly</Alert>}               
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
            {lists && (
            <Grid item xs={6}>
                <CSVLink
                    data={lists}
                    filename={`lists.csv`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                >
                    <Button variant="contained" startIcon={<Download />}>Download lists</Button>
                </CSVLink>
            </Grid>)}
        </Grid>
    );
}

export default SmartvoteToVimentisConverter;