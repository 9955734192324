import { Download } from "@mui/icons-material";
import { Grid, Typography, Button, LinearProgress, Alert } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import defaultColDef from "../../../constants/defaultColDef";
import Answer from "../../../interface/answer";
import { getAnswers, getQuestions } from "../../../services/api.service";

function AnswersDashboard(): JSX.Element {
    const params = useParams();
    const id = params.id ? parseInt(params.id) : 0;

    const { data: answers, error, isLoading } = useQuery<Answer[]>(["election-answers", { id }], async () => await getAnswers(id));
    const { data: questions, error: qError, isLoading: qIsLoading } = useQuery<any>(["questions", { id }], async () => await getQuestions(id));

    const questionsMap: Map<number, any> = new Map<number, any>();
    if (Array.isArray(questions) && questions.length) {
        for (const q of questions) questionsMap.set(q.id, q);
    } 

    if (error || qError) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading || qIsLoading) return <LinearProgress />;

    if (answers) {
        const rowData: {id: number, answer: string, questionID: number, question?: string;}[] = [];
        for (const a of answers) rowData.push( { question: questionsMap.get(a.questionID)?.text, ...a} )
        return (
            <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
                <Typography variant={"h5"}>Antworten</Typography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="end">
                <CSVLink
                    data={rowData}
                    filename={`${id}-answers.csv`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                >
                    <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                </CSVLink>
            </Grid>
            <Grid item xs={12}>
                <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                    <AgGridReact
                        rowData={rowData}
                        rowSelection="single"
                        defaultColDef={defaultColDef}
                        columnDefs={[
                            { field: 'id' },
                            { field: 'answer' },
                            { field: 'questionID' },
                            { field: 'question' },
                        ]}
                    />
                </div>
            </Grid>
        </Grid>
        );
    }

    return <></>;
}


export default AnswersDashboard;