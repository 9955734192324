import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateQuestion } from "../../../services/api.service";
import Question from "../../../interface/question";
import UpdateQuestionDTO from "../../../interface/dto/update/question";

function QuestionsDetailDialog({ question, open, onClose }: { question?: Question, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            isInShortMode: 0, 
            text: "",
            info: "", 
            pro: "",
            contra: ""
        }
    });

    useEffect(() => {
        if (question) {
            setValue("isInShortMode", question.isInShortMode ? 1 : 0);
            setValue("text", question.text);
            setValue("info", question.info);
            setValue("pro", question.pro);
            setValue("contra", question.contra);
        }
    }, [question, setValue])

    const onSubmit = async (data: any) => {
        if (!question) throw Error("No question selected");
        let updatedQuestion: UpdateQuestionDTO = {
            id: question.id,
            isInShortMode: data.isInShortMode,
            text: data.text,
            info: data.info,
            pro: data.pro,
            contra: data.contra
        };
        const res = await updateQuestion(updatedQuestion);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated Question, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Frage Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Frage</InputLabel>
                                    <Controller
                                        name="text"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Info Text</InputLabel>
                                    <Controller
                                        name="info"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Pro Text</InputLabel>
                                    <Controller
                                        name="pro"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Contra Text</InputLabel>
                                    <Controller
                                        name="contra"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default QuestionsDetailDialog;
