import { Add, Download, ModeEdit, Send } from "@mui/icons-material";
import { Alert, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CandidatesTemplate } from "../../../constants/csvTemplates";
import defaultColDef from "../../../constants/defaultColDef";
import Candidate from "../../../interface/candidate";
import { getCandidates, sendInvitationMails } from "../../../services/api.service";
import { SelectionChangedEvent } from "ag-grid-community";
import CandidateDetailDialog from "../../Detail/ElectionDialogs/CandidateDetailDialog";

function CandidatesDashboard(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? parseInt(params.id) : 0;
    const [showSendMail, setShowSendMail] = useState<boolean>(false);
    const [message, setMessage] = useState<JSX.Element>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();

    const { data: candidates, error, isLoading } = useQuery<Candidate[]>(["election-candidates", { id }], async () => await getCandidates(id));

    const handleModalClose = () => setModalOpen(false);

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows: Candidate[] = event.api.getSelectedRows();
        setSelectedCandidate(selectedRows.length ? selectedRows[0] : undefined);
    }

    const sendMails = async () => {
        const res = await sendInvitationMails(id); 
        if (res) {
            setMessage(<Alert severity="success">{res.mailsSent} Mails Sent!</Alert>)
        }
        console.log(res);
    }


    if (error) return <Alert severity="error">Error Loading</Alert>;
    if (isLoading) return <LinearProgress />;

    if (candidates) {
        if (!showSendMail && candidates.find(c => !c.mailSent)) setShowSendMail(true);
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>Kandidaten</Typography>
                </Grid>
                <Grid item xs={12}>
                    {message}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="start">
                    <CSVLink
                        data={candidates}
                        filename={`${id}-kandidaten.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" startIcon={<Download />}>CSV Export</Button>
                    </CSVLink>
                    <CSVLink
                        data={CandidatesTemplate}
                        filename={`template-candidates.csv`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button sx={{ml: 1}} variant="contained" startIcon={<Download />}>CSV Template</Button>
                    </CSVLink>
                    <Button sx={{ml: 1}} variant="contained" onClick={() => sendMails()} startIcon={<Send />} disabled={!showSendMail}>Send Invitiation Mail</Button>
                    {selectedCandidate && <Button sx={{ml: 1}} variant="contained" color="success" onClick={() => setModalOpen(true)}><ModeEdit /></Button>}
                    <Button sx={{ml: 1}} variant="contained" onClick={() => navigate(`/election/${id}/candidates/create`)}><Add /></Button>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "70vh" }}>
                        <AgGridReact
                            rowData={candidates}
                            rowSelection="single"
                            defaultColDef={defaultColDef}
                            onSelectionChanged={onSelectionChanged}
                            columnDefs={[
                                { field: 'candidateID' },
                                { field: 'candidateName' },
                                { field: 'gender' },
                                { field: 'double' },
                                { field: 'partyName' },
                                { field: 'listID' },
                                { field: 'listName' },
                                { field: 'listPlace' },
                                { field: 'profession' },
                                { field: 'email' },
                                { field: 'wahl' },
                                { field: 'already' },
                                { field: 'double' },
                                { field: 'canton' },
                                { field: 'wahlkreis' },
                                { field: 'mailSent' },
                                { field: 'token' },
                                { field: 'tokenUsed' },
                                { field: 'yearBorn' }
                            ]}
                        />
                    </div>
                </Grid>
                <CandidateDetailDialog
                    open={modalOpen}
                    candidate={selectedCandidate}
                    onClose={handleModalClose}
                />
            </Grid>
        );
    }

    return <></>
}


export default CandidatesDashboard;