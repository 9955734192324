
import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, Input, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateImpact } from "../../../services/api.service";
import Impact from "../../../interface/impact";

function ImpactDetailDialog({ impact, open, onClose }: { impact?: Impact, open: boolean, onClose: () => void }) {
    const [message, setMessage] = useState<JSX.Element>();
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            impact: 1,
        }
    });

    useEffect(() => {
        if (impact) {
            setValue("impact", impact.impact);
        }
    }, [impact, setValue])

    const onSubmit = async (data: any) => {
        if (!impact) throw Error("No Impact selected");
        let updatedImpact: {impact: number, answer_id: number, criteria_id: number} = {
            criteria_id: impact.criteriaId,
            answer_id: impact.answerID,
            impact: data.impact,
        };
        const res = await updateImpact(updatedImpact);
        if (res.updated) {
            setMessage(<Alert severity="success">Updated List, Closing in 2 Seconds</Alert>)
            setTimeout(() => onClose(), 2000)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Impact Updaten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>

                    <Grid item xs={12}>
                        <form>
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel>Name</InputLabel>
                                    <Controller
                                        name="impact"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input type="number" {...field} fullWidth />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ImpactDetailDialog;
