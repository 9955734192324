import { Alert, Grid, Typography } from "@mui/material";
import { useState } from "react";
import CSVUploader from "../../components/CSVUploader";
import { WahlkreisHeader } from "../../constants/csvTemplates";
import { createWahlkreise } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";
import Wahlkreise from "../../interface/Wahlkreise";

function WahlkreisBulkImport() {
    const [message, setMessage] = useState<JSX.Element>();

    const onUploadAccepted = async (results: any) => {
        const isValid = validateCSV(results, WahlkreisHeader);
        if (isValid === true) {
            const wahlkreiseRaw = results.data.slice(1);
            const wahlkreise: Partial<Wahlkreise>[] = [];
            for (const w of wahlkreiseRaw) {
                wahlkreise.push({
                    name: w[0],
                    kanton: w[1]
                });
            }

            if (wahlkreise.length) {
                const res = await createWahlkreise(wahlkreise);
                if (res) {
                    setMessage(<Alert severity="success">Wahlkreise created</Alert>);
                    return;
                } else {
                    setMessage(<Alert severity="error">Error while adding Wahlkreise</Alert>);
                    return;
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
            return;
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Wahlkreise</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default WahlkreisBulkImport;
