import Answer from "../interface/answer";
import Candidate from "../interface/candidate";
import Criteria from "../interface/criteria";
import AnswerDTO from "../interface/dto/answer";
import CandidateDTO from "../interface/dto/candiate";
import CriteriaDTO from "../interface/dto/criteria";
import ElectionDTO from "../interface/dto/election";
import ImpactDTO from "../interface/dto/impact";
import ListDTO from "../interface/dto/list";
import PartyDTO from "../interface/dto/party";
import QuestionDTO from "../interface/dto/question";
import QuestionCategoryDTO from "../interface/dto/question-category";
import WahlkreiseDTO from "../interface/dto/wahlkreise";
import Election from "../interface/election";
import Impact from "../interface/impact";
import List from "../interface/list";
import Party from "../interface/party";
import Question from "../interface/question";
import QuestionCategory from "../interface/question-category";
import Wahlkreise from "../interface/Wahlkreise";

export function dtoToElection(eDTO: ElectionDTO): Election {
    return {
        date: new Date(eDTO.date),
        description: eDTO.description,
        hasCantons: eDTO.has_cantons === "1" ? true : false,
        hasWahlkreise: eDTO.has_wahlkreise === "1" ? true : false,
        id: parseInt(eDTO.id),
        image: eDTO.image,
        isPublic: eDTO.is_public === "1" ? true : false,
        multiple: eDTO.multiple === "1" ? true : false,
        name: eDTO.name,
        postID: eDTO.post_id ? parseInt(eDTO.post_id) : null
    }
}

export function dtoToAnswer(aDTO: AnswerDTO): Answer {
    return {
        id: parseInt(aDTO.id),
        answer: aDTO.answer,
        questionID: parseInt(aDTO.question_id)
    }
}

export function dtoToQuestion(qDTO: QuestionDTO): Question {
    return {
        id: parseInt(qDTO.id),
        categoryID: parseInt(qDTO.category_id),
        contra: qDTO.contra,
        electionID: parseInt(qDTO.election_id),
        info: qDTO.info,
        isInShortMode: qDTO.isInShortMode === "1" ? true : false,
        pro: qDTO.pro,
        text: qDTO.text,

    }
}

export function dtoToCriteria(cDTO: CriteriaDTO): Criteria {
    return {
        id: parseInt(cDTO.id),
        electionID: parseInt(cDTO.election_id),
        criteriaName: cDTO.criteria_name,
        defaultValue: parseInt(cDTO.default_value)
    }
}

export function dtoToCandidate(cDTO: CandidateDTO): Candidate {
    console.log(cDTO.year_born);
    return {
        already: cDTO.already === "1" ? true : false,
        candidateID: parseInt(cDTO.candidate_id),
        candidateName: cDTO.candidate_name,
        double: cDTO.double === "1" ? true : false,
        email: cDTO.email,
        gender: cDTO.gender,
        listID: parseInt(cDTO.list_id),
        listPlace: parseInt(cDTO.list_place),
        mailSent: cDTO.mail_sent === "1" ? true : false,
        listName: cDTO.list_name,
        partyName: cDTO.party_name,
        profession: cDTO.profession,
        token: cDTO.token,
        tokenUsed: cDTO.token_used === "1" ? true : false,
        userID: cDTO.user_id ? parseInt(cDTO.user_id) : null,
        wahl: cDTO.wahl === "" ? null : cDTO.wahl,
        yearBorn: parseInt(cDTO.year_born), 
    }
}

export function dtoToList(l: ListDTO): List {
    return {
        electionID: parseInt(l.election_id),
        id: parseInt(l.id), 
        name: l.name, 
        partyId: parseInt(l.party_id),
        partyColor: l.party_color,
        partyName: l.party_name,
        partyShortname: l.party_shortname,
        wahl: l.wahl === "" ? null : l.wahl, 
        bezirksname: l.bezirksname === "" ? null : l.bezirksname, 
        bezirksNr: l.wahlkreis_id === "" ? null : parseInt(l.wahlkreis_id),
        kanton: l.kanton === "" ? null : l.kanton,
    }
}

export function dtoToQuestionCategory(c: QuestionCategoryDTO): QuestionCategory {
    return {
        id: parseInt(c.id),
        electionID: parseInt(c.election_id),
        name: c.name
    }
}

export function dtoToParty(p: PartyDTO): Party {
    return {
        id: parseInt(p.id),
        color: p.party_color,
        name: p.party_name,
        shortname: p.party_shortname,
        partyLogoUrl: p.party_logo_url
    }
}

export function dtoToWahlkreise(w: WahlkreiseDTO): Wahlkreise {
    return {
        kanton: w.kanton,
        wahlkreis_id: parseInt(w.wahlkreis_id), 
        name: w.name
    };
}

export function dtoToImpact(i: ImpactDTO): Impact {
    return {
        answerID: parseInt(i.answer_id),
        criteriaId: parseInt(i.criteria_id),
        criteriaName: i.criteria_name, 
        defaultValue: parseInt(i.default_value),
        electionID: parseInt(i.election_id),
        id: parseInt(i.id),
        impact: parseInt(i.impact),
    }
}

