import { Alert, Button, Grid, Input, InputLabel, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createWahlname } from "../../services/api.service";

function ElectionNameDetail() {
    const { handleSubmit, control } = useForm();
    const [message, setMessage] = useState<JSX.Element>();

    const onSubmit = async (data: any) => {
        let wahlname = {
            wahlname: data.name,
        };
        const res = await createWahlname(wahlname);
        if (res.created) setMessage(<Alert severity="success">Created Wahl</Alert>);
    }

    return (
        <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Wahl</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <form>
                    <Grid item container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Wahlname </InputLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default ElectionNameDetail;
