import './App.css';
import AppContainer from './pages/AppContainer';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboards/Election/ElectionsDashboard';
import { QueryClient, QueryClientProvider } from 'react-query';
import ElectionDashboard from './pages/Dashboards/Election/ElectionDashboard';
import { createTheme, ThemeProvider } from '@mui/material';
import CriteriaDashboard from './pages/Dashboards/Election/CriteriaDashboard';
import QuestionsDashboard from './pages/Dashboards/Election/QuestionsDashboard';
import CandidatesDashboard from './pages/Dashboards/Election/CandidatesDashboard';
import ListsDashboard from './pages/Dashboards/Election/ListsDashboard';
import OverviewDashboard from './pages/Dashboards/Election/OverviewDashboard';
import CategoriesDashboard from './pages/Dashboards/Election/CategoriesDashboard';
import PartiesDashboard from './pages/Dashboards/PartiesDashboard';
import ElectionNameDashboard from './pages/Dashboards/ElectionNameDashboard';
import DistrictsDashboard from './pages/Dashboards/DistrictsDashboard';
import ElectionDetail from './pages/Detail/ElectionDetail';
import PartyDetail from './pages/Detail/PartyDetail';
import ElectionNameDetail from './pages/Detail/ElectionNameDetail';
import ImpactBulkImportPage from './pages/BulkImport/ImpactBulkImport';
import ListsBulkImportPage from './pages/BulkImport/ListsBulkImport';
import CandidatesBulkImportPage from './pages/BulkImport/CandidatesBulkImport';
import CategoryBulkImportPage from './pages/BulkImport/CategoriesBulkImport';
import CriteriaBulkImportPage from './pages/BulkImport/CriteriaBulkImport';
import QuestionsBulkImportPage from './pages/BulkImport/QuestionsBulkImport';
import ImpactDashboard from './pages/Dashboards/Election/ImpactDashboard';
import NotFound from './pages/NotFound';
import { AuthProvider } from './context/authContext';
import SignIn from './pages/SignIn';
import Protected from './components/Protected';
import AnswersDashboard from './pages/Dashboards/Election/AnswersDashboard';
import SmartvoteToVimentisConverter from './pages/SmartvoteToVimentisConverter';
import WahlkreisBulkImport from './pages/BulkImport/WahlkreisBulkImport';
import ListIdCalculator from './pages/Dashboards/Election/ListIdCalculator';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#3460A9",
    },
    secondary: {
      main: "#061735"
    }
  },
});


function App() {

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function AppRoutes() {
  return (
  <Routes>
    <Route path='' element={<Navigate to="/dashboard" />} />
    <Route path="/sign-in" element={<SignIn /> } />
    <Route path="/" element={<AppContainer />}>
      <Route path="dashboard" index element={<Protected><Dashboard /></Protected>} />
      <Route path="smartvote-to-vimentis" element={<Protected><SmartvoteToVimentisConverter /></Protected>} />
      <Route path="election/:id" element={<ElectionDashboard />}>
        <Route element={<Protected><OverviewDashboard /></Protected>} />
        <Route path="answers" element={<Protected><AnswersDashboard /></Protected>} />
        <Route path="impact" element={<Protected><ImpactDashboard /></Protected>} />
        <Route path="impact/create" element={<Protected><ImpactBulkImportPage /></Protected>} />
        <Route path="criteria" element={<Protected><CriteriaDashboard /></Protected>} />
        <Route path="criteria/create" element={<Protected><CriteriaBulkImportPage /></Protected>} />
        <Route path="questions" element={<Protected><QuestionsDashboard /></Protected>} />
        <Route path="questions/create" element={<Protected><QuestionsBulkImportPage /></Protected>} />
        <Route path="question-categories" element={<Protected><CategoriesDashboard /></Protected>} />
        <Route path="question-categories/create" element={<Protected><CategoryBulkImportPage /></Protected>} />
        <Route path="candidates" element={<Protected><CandidatesDashboard /></Protected>} />
        <Route path="candidates/create" element={<Protected><CandidatesBulkImportPage /></Protected>} />
        <Route path="lists" element={<Protected><ListsDashboard /></Protected>} />
        <Route path="list-id-calculator" element={<Protected><ListIdCalculator /></Protected>} />
        <Route path='lists/create' element={<Protected><ListsBulkImportPage /></Protected>} />
      </Route>
      <Route path="parties" element={<Protected><PartiesDashboard /></Protected>} />
      <Route path="wahlen" element={<Protected><ElectionNameDashboard /></Protected>} />
      <Route path="wahlkreise" element={<Protected><DistrictsDashboard /></Protected>} />
      <Route path="wahlkreise/create" element={<Protected><WahlkreisBulkImport /></Protected>} />
      <Route path='election/create/' element={<Protected><ElectionDetail /></Protected>} />
      <Route path="parties/create" element={<Protected><PartyDetail /></Protected>} />
      <Route path="wahlen/create" element={<Protected><ElectionNameDetail /></Protected>} />
      <Route path='*' element={<NotFound />} />
    </Route>
  </Routes>
  );

}

export default App;