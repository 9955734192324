import { useQuery } from "react-query";
import { getElection } from "../../../services/api.service";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Alert, Breadcrumbs, Grid, LinearProgress, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Calculate, Category, Flare, ListAlt, PeopleAlt, QuestionAnswer, QuestionMark, Rule } from "@mui/icons-material";
import Election from "../../../interface/election";

function ElectionDashboard(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();

    const id = params.id ? parseInt(params.id) : 0;
    const rootUrl = `/election/${id}`;

    const { data: election, error, isLoading } = useQuery<Election>(["election", { id }], async () => await getElection(id));

    if (isLoading) return <LinearProgress />;

    if (error) return <Alert severity="error">Error Loading</Alert>;

    if (election) {
        return (
            <Grid container p={2}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard">
                            Dashboard
                        </Link>
                        <Typography color="text.primary">{election.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={4}>
                    <List>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/criteria`)}>
                            <ListItemIcon><Rule /></ListItemIcon>
                            <ListItemText>Bewertungskriterien</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/lists`)}>
                            <ListItemIcon><ListAlt /></ListItemIcon>
                            <ListItemText>Listen</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/candidates`)}>
                            <ListItemIcon><PeopleAlt /></ListItemIcon>
                            <ListItemText>Kandidaten</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/question-categories`)}>
                            <ListItemIcon><Category /></ListItemIcon>
                            <ListItemText>Fragen Kategorien</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/questions`)}>
                            <ListItemIcon><QuestionMark /></ListItemIcon>
                            <ListItemText>Fragen</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/answers`)}>
                            <ListItemIcon><QuestionAnswer /></ListItemIcon>
                            <ListItemText>Antworten</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(`${rootUrl}/impact`)}>
                            <ListItemIcon><Flare /></ListItemIcon>
                            <ListItemText>Impact</ListItemText>
                        </ListItemButton>

                        <ListItemButton onClick={() => navigate(`${rootUrl}/list-id-calculator`)}>
                            <ListItemIcon><Calculate /></ListItemIcon>
                            <ListItemText>Calculate List ID</ListItemText>
                        </ListItemButton>
                    </List>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Outlet />
                </Grid>
            </Grid>
        );
    }
    return <></>;

}

export default ElectionDashboard;