import { Alert, Button, Checkbox, FormControlLabel, Grid, Input, InputLabel, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createElection } from "../../services/api.service";

function ElectionDetail() {
    const { handleSubmit, control } = useForm();
    const [message, setMessage] = useState<JSX.Element>();
    const onSubmit = async (data: any) => {
        let wahlInfo = {
            name: data.name,
            description: data.description,
            date: data.date,
            image: data.image,
            hasWahlkreise: data.hasWahlkreise ?? false, 
            multiple: data.hasMultipleWahlen ?? false,
            hasCantons: data.hasCantons ?? false
        }

        const res = await createElection(wahlInfo);
        if (res) {
            setMessage(<Alert severity="success">Wahl erstellt!</Alert>);
        } else {
            setMessage(<Alert severity="error">Es gab einen Fehler beim erstelen der Wahl</Alert>);
        }
    }

    return (
        <Grid container spacing={2} p={5}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Wahl</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <form>
                    <Grid item container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Name</InputLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Beschreibung</InputLabel>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Bild URL</InputLabel>
                            <Controller
                                name="image"
                                control={control}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Datum der Wahl (Format YYYY-MM-DD)</InputLabel>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => <Input {...field} fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Controller
                                    name="hasCantons"
                                    control={control}
                                    render={({ field }) => <Checkbox {...field} />}
                                />}
                                label="Ist die Wahl in mehreren Kantonen" />
                        </Grid>


                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Controller
                                    name="hasWahlkreise"
                                    control={control}
                                    render={({ field }) => <Checkbox {...field} />}
                                />}
                                label="Ist die Wahl in mehreren Wahlkreisen?" />
                        </Grid>


                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Controller
                                    name="hasMultipleWahlen"
                                    control={control}
                                    render={({ field }) => <Checkbox {...field} />}
                                />}
                                label="Sind in der Wahl mehrere Wahlen?" />
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default ElectionDetail;