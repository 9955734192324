import { Button, Grid, Input, InputLabel, Typography } from "@mui/material";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function SignIn() {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm();
    
    const onSubmit = async (data: any) => {
        const res = await authContext.signIn({ username: data.username, password: data.password})
        if (res) navigate("/dashboard");
    }

    return (
        <Grid 
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            sx={{background: "url(https://vimentis.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/11/01154929/mortaza-shahed-jXZ4Tnt_Cx0-unsplash-1.jpg)"}}
        >
            <Grid item xs={12} md={4} lg={3} sx={{backgroundColor: "#e3e3e3", borderRadius: 5, p: 4}}>
                <Typography variant="h5">Sign In</Typography>
                <Grid item container spacing={3} pt={2}>
                    <Grid item xs={12}>
                        <InputLabel>Username</InputLabel>
                        <Controller
                            name="username"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <Input {...field} fullWidth />}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel>Password</InputLabel>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <Input type="password" {...field} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>Sign in</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignIn;