import { Alert, Grid, LinearProgress, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { CandidatesHeader } from "../../constants/csvTemplates";
import Candidate from "../../interface/candidate";
import List from "../../interface/list";
import { createCandidates, getLists } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";
import { getListPlace, getYearBorn, validateAllowedYesAnswer, validateEmail, validateGender } from "../../helpers/validation.helper";

function CandidatesBulkImportPage({ candidate }: { candidate?: Candidate }) {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();

    const { data: lists, isLoading } = useQuery<List[]>(["election-lists", { electionID }], async () => await getLists(electionID));

    const listMap = useMemo(() => {
        const tmpListMap = new Map<number, List>();
        if (lists) for (const l of lists) tmpListMap.set(l.id, l);
        return tmpListMap;
    }, [lists]);


    const onUploadAccepted = async (results: any) => {
        try {
            const isValid = validateCSV(results, CandidatesHeader)
            if (isValid !== true) throw new Error(isValid);
            if (!listMap) throw new Error("Listen konnten nicht geladen.")
            const data = results.data.slice(1);
            const candidates = [];
            for (const [i, cDTO] of data.entries()) {
                const list_id = cDTO[0];
                const email = cDTO[1];
                const name = cDTO[2];
                const profession = cDTO[3];
                const gender = validateGender(cDTO[4]);
                const yearBorn = getYearBorn(cDTO[5]);
                const already = validateAllowedYesAnswer(cDTO[6]);
                const double = validateAllowedYesAnswer(cDTO[7]);
                const list_place = getListPlace(cDTO[8]);

                if (!validateEmail(email)) 
                    throw new Error(`Email ${email} is not a valid email on line: ${i+2}`);
                if (!gender) 
                    throw new Error(`Couldnt Match gender String: '${cDTO[4]}' to Male, Female or X Gender on line: ${i+2}`);
                if (!listMap.get(parseInt(list_id))) 
                    throw new Error(`Liste mit ID: ${list_id} konnte nicht gefunden werden. Objekt: ${JSON.stringify(cDTO)} on Line: ${i + 2}`);

                candidates.push({
                    list_id, email, name, profession, gender, yearBorn, already, double, list_place
                });
            }

            if (candidates.length > 0) {
                const res = await createCandidates(electionID, { candidates: candidates });
                if (res)
                    setMessage(<Alert severity="success">Candidates created</Alert>);
                else
                    throw new Error("Error while adding Candidates from API");
            } else {
                throw new Error("No Candidates to create");
            }
        } catch (error) {
            if (error instanceof Error) setMessage(<Alert severity="error">{error.message}</Alert>)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Kandidaten</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
                {isLoading && <LinearProgress />} 
                {(!isLoading && !lists) && <Alert severity="warning">In dieser Wahl wurden keine Listen gefunden. Erstelle zuerst Listen um Kandidaten erstellen zu können.</Alert>}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default CandidatesBulkImportPage;
