import { Alert, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { ListsHeader } from "../../constants/csvTemplates";
import Party from "../../interface/party";
import Wahlkreise from "../../interface/Wahlkreise";
import Wahlname from "../../interface/Wahlname";
import { createLists, getParties, getWahlen, getWahlkreise } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";


function ListsBulkImportPage() {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();
    const [partyMap, setPartyMap] = useState<Map<number, Party>>();
    const [wahlen, setWahlen] = useState<string[]>();
    const [wahlkreiseMap, setWahlkreiseMap] = useState<Map<number, Wahlkreise>>();

    const { data: parties } = useQuery<Party[]>(["parties"], async () => await getParties());
    const { data: wahlenData } = useQuery<Wahlname[]>(["wahlen"], async () => await getWahlen());
    const { data: wahlkreise } = useQuery<Wahlkreise[]>(["wahlkreise"], async () => await getWahlkreise());

    useEffect(() => {
        if (parties && !partyMap) {
            const tmpPartyMap = new Map<number, Party>();
            for (const p of parties) tmpPartyMap.set(p.id, p);
            if (tmpPartyMap.size === 0) {
                setMessage(<Alert severity="warning">Im System wurden keine Parteien gefunden. Erstelle zuerst Parteien!</Alert>)
            } else {
                setPartyMap(tmpPartyMap);
            }
        }
    }, [parties, partyMap]);

    useEffect(() => {
        if (Array.isArray(wahlenData)) {
            if (wahlenData.length === 0) {
                setMessage(<Alert severity="warning">Im System wurden keine Wahlen gefunden. Erstelle zuerst Wahlen!</Alert>)
            } else {
                const strArr: string[] = [];
                for (const w of wahlenData) strArr.push(w.wahlname);
                setWahlen(strArr);
            }
        }
    }, [wahlenData]);

    useEffect(() => {
        if (wahlkreise && !wahlkreiseMap) {
            const tmpWahlkreiseMap = new Map<number, Wahlkreise>();
            for (const w of wahlkreise) tmpWahlkreiseMap.set(w.wahlkreis_id, w);
            console.log(wahlkreise);
            if (tmpWahlkreiseMap.size === 0) {
                setMessage(<Alert severity="warning">Im System wurden keine Wahlkreise gefunden. Erstelle zuerst Wahlkreise!</Alert>)
            } else {
                setWahlkreiseMap(tmpWahlkreiseMap);
            }
        }
    }, [wahlkreise, wahlkreiseMap]);

    const onUploadAccepted = async (results: any) => {
        console.log(results);
        const isValid = validateCSV(results, ListsHeader)
        if (isValid === true) {
            const listsRaw = results.data.slice(1);
            const lists = [];
            for (const l of listsRaw) {
                lists.push({
                    party_id: l[0],
                    name: l[1],
                    wahlkreis_id: l[2],
                    wahl: l[3],
                });
            }

            // custom validation for lists; 
            // party_id must exist; wahlkreis_id must exist; wahl must exist;
            if (!partyMap) {
                setMessage(<Alert severity="error">Parteien konnten nicht geholt werden.</Alert>);
                return;
            }

            if (!wahlen) {
                setMessage(<Alert severity="error">Wahlen konnten nicht geholt werden.</Alert>);
                return;
            }

            if (!wahlkreiseMap) {
                setMessage(<Alert severity="error">Wahlkreise konnten nicht geholt werden.</Alert>);
                return;
            }
            for (const l of lists) {
                if (!partyMap.get(parseInt(l.party_id))) {
                    setMessage(<Alert severity="error">Partei mit ID: {l.party_id} konnte nicht gefunden werden. Objekt: {JSON.stringify(l)}</Alert>);
                    return;
                }
                if (!wahlen.find((val) => val === l.wahl)) {
                    setMessage(<Alert severity="error">Wahl: {l.wahl} konnte nicht in Wahlen gefunden werden. Objekt: {JSON.stringify(l)}; Verfügbare Wahlen: {JSON.stringify(wahlen)}</Alert>);
                    return;
                }
                if (!wahlkreiseMap.get(parseInt(l.wahlkreis_id))) {
                    setMessage(<Alert severity="error">Wahlkreis mit Bezirks Nr: {l.wahlkreis_id} konnte nicht gefunden werden. Objekt: {JSON.stringify(l)}</Alert>);
                    return;
                }
            }

            if (lists.length) {
                const res = await createLists(electionID, { lists: lists });
                if (res) {
                    setMessage(<Alert severity="success">Listen erstellt</Alert>)
                } else {
                    setMessage(<Alert severity="error">Fehler beim erstellen der Listen</Alert>)
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Listen</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default ListsBulkImportPage;
