import { Alert, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { FragenKategorienHeader } from "../../constants/csvTemplates";
import { createQuestionCategories } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";

/**
 * Create Question Category in wahl; 
 * id, name, electionID; 
 * 
 */
function CategoryBulkImportPage() {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();

    const onUploadAccepted = async (results: any) => {
        console.log(results);
        const isValid = validateCSV(results, FragenKategorienHeader);
        if (isValid === true) {
            const categoriesRaw = results.data.slice(1);
            const categories = [];
            for (const q of categoriesRaw) {
                categories.push({
                    name: q[0],
                });
            }

            if (categories.length) {
                const res = await createQuestionCategories(electionID, { categories: categories });
                if (res) {
                    setMessage(<Alert severity="success">Fragenkategorien created</Alert>)
                } else {
                    setMessage(<Alert severity="error">Error while adding Fragenkategorien</Alert>)
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Fragen Kategorien</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default CategoryBulkImportPage;
