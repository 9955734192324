import { Alert, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { ImpactHeader } from "../../constants/csvTemplates";
import Answer from "../../interface/answer";
import Criteria from "../../interface/criteria";
import { createImpact, getAnswers, getCriteria } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";

function ImpactBulkImportPage() {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();
    const [answerMap, setAnswerMap] = useState<Map<number, Answer>>();
    const [criteriaMap, setCriteriaMap] = useState<Map<number, Criteria>>();


    const { data: answers } = useQuery<Answer[]>(["election-answers", { electionID }], async () => await getAnswers(electionID));
    const { data: criteria } = useQuery<Criteria[]>(["election-criteria", { electionID }], async () => await getCriteria(electionID));

    useEffect(() => {
        if (answers && !answerMap) {
            const tmpAnswerMap = new Map<number, Answer>();
            for (const a of answers) tmpAnswerMap.set(a.id, a);
            if (tmpAnswerMap.size === 0) {
                setMessage(<Alert severity="warning">In dieser Wahl wurden keine Antworten gefunden. Erstelle zuerst Fragen um Impacts erstellen zu können.</Alert>)
            } else {
                setAnswerMap(tmpAnswerMap);
            }
        }
    }, [answers, answerMap]);

    useEffect(() => {
        if (criteria && !criteriaMap) {
            const tmpCriteriaMap = new Map<number, Criteria>();
            for (const c of criteria) tmpCriteriaMap.set(c.id, c);
            if (tmpCriteriaMap.size === 0) {
                setMessage(<Alert severity="warning">In dieser Wahl wurden keine Kriterien gefunden. Erstelle zuerst Kriterien um Impacts erstellen zu können.</Alert>);
            } else {
                setCriteriaMap(tmpCriteriaMap);
            }
        }
    }, [criteria, criteriaMap]);

    const onUploadAccepted = async (results: any) => {
        console.log(results);
        const isValid = validateCSV(results, ImpactHeader);
        if (isValid === true) {
            const impactRaw = results.data.slice(1);
            const impact = [];
            for (const i of impactRaw) {
                impact.push({
                    answer_id: i[0],
                    criteria_id: i[1],
                    impact: i[2]
                });
            }

            // validate custom impact
            // validation rules: answer_id must exist; critieria_id must exist; impact must be an int and be bigger than -10 and smaller than 10;
            if (!answerMap) {
                setMessage(<Alert severity="error">Antworten konnten nicht geholt werden.</Alert>);
                return;
            }

            if (!criteriaMap) {
                setMessage(<Alert severity="error">Kriterien konnten nicht geholt werden.</Alert>);
                return;
            }

            for (const i of impact) {
                if (!answerMap.get(parseInt(i.answer_id))) {
                    setMessage(<Alert severity="error">Antwort mit ID: {i.answer_id} konnte nicht gefunden werden. Objekt: {JSON.stringify(i)}</Alert>);
                    return;
                }
                if (!criteriaMap.get(parseInt(i.criteria_id))) {
                    setMessage(<Alert severity="error">Kriterium mit ID: {i.criteria_id} konnte nicht gefunden werden. Objekt: {JSON.stringify(i)}</Alert>);
                    return;
                }
                const impactInt = parseFloat(i.impact);
                if (isNaN(impactInt)) {
                    setMessage(<Alert severity="error">{impactInt} ist keine Zahl. Der Impakt muss eine Zahl sein! Objekt: {JSON.stringify(i)}</Alert>);
                    return;
                }
            }

            if (impact.length) {
                const res = await createImpact({ impact: impact });
                if (res) {
                    setMessage(<Alert severity="success">Impact created</Alert>)
                } else {
                    setMessage(<Alert severity="error">Error while creating Impact</Alert>)
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
        }
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Impact</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default ImpactBulkImportPage;
