import { Alert, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CSVUploader from "../../components/CSVUploader";
import { BewertungskriterienHeader } from "../../constants/csvTemplates";
import { createBewertungskriterien } from "../../services/api.service";
import { validateCSV } from "../../services/validation.service";

function CriteriaBulkImportPage() {
    const params = useParams();
    const electionID = params.id ? parseInt(params.id) : 0;
    const [message, setMessage] = useState<JSX.Element>();

    const onUploadAccepted = async (results: any) => {
        console.log(results);
        const isValid = validateCSV(results, BewertungskriterienHeader)

        if (isValid === true) {
            const criteriaRaw = results.data.slice(1);
            const criteria = [];
            for (const c of criteriaRaw) {
                criteria.push({
                    name: c[0],
                    value: c[1],
                });
            }

            if (criteria.length) {
                const res = await createBewertungskriterien(electionID, { criteria: criteria });
                if (res) {
                    setMessage(<Alert severity="success">Criteria created</Alert>)
                } else {
                    setMessage(<Alert severity="error">Error while creating Criteria from the API</Alert>)
                }
            }
        } else {
            setMessage(<Alert severity="error">{isValid}</Alert>)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Erstelle Bewertungskriterien</Typography>
            </Grid>
            <Grid item xs={12}>
                {message}
            </Grid>
            <Grid item xs={12}>
                <CSVUploader onUploadAccepted={onUploadAccepted} />
            </Grid>
        </Grid>
    );
}

export default CriteriaBulkImportPage;
